import * as React from 'react';
import { Container, Link, Text } from '@chakra-ui/react';

import SiteHeader from 'src/components/SiteHeader';

export default function Custom404() {
  return (
    <Container centerContent mt="123px" p={0} maxW="800px">
      <SiteHeader />
      <Text
        data-cy="support-message"
        mt="48px"
        mx="48px"
        size="m"
        textAlign="center"
        variant="light"
        maxW="600px"
      >
        We&apos;re sooo sorry, but can you go back and make sure the link is spelled right. If not,
        email us at{' '}
        <Link href={`mailto:${process.env.SUPPORT_EMAIL}`}>{process.env.SUPPORT_EMAIL}</Link>.
      </Text>
    </Container>
  );
}
